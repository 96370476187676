<template>
	<el-drawer
		title="查看客户信息"
		:visible.sync="drawer_"
		destroy-on-close
		direction="rtl"
		custom-class="demo-drawer"
		ref="drawer"
		size="55%"
		@open="open"
		@close="
			() => {
				$emit('closeVisible');
			}
		"
	>
		<div class="demo-drawer__content" style="width: 100%">
			<div class="edit" style="padding: 0 30px; width: 100%">
				<el-card class="box-card">
					<el-form
						:model="ruleForm"
						status-icon
						class="demo-ruleForm"
						label-position="rigth"
						label-width="120px"
						v-if="ruleForm.whetherSensitive"
					>
						<el-form-item label="客户名称:">
							<div
								@click="$emit('openPublicInfoDrawer', ruleForm)"
								style="color: #02a7f0; cursor: pointer"
							>
								{{
									ruleForm.whetherSensitive == 1
										? ruleForm.pdfClientName
										: ruleForm.clientName
								}}
							</div>
						</el-form-item>
						<el-form-item label="合作意愿:">
							{{ ruleForm.cooperateWillingnessName }}
						</el-form-item>
						<el-form-item
							v-if="ruleForm.cooperateWillingness != 3"
							label="适用产品:"
						>
							{{ ruleForm.applicableProducts }}
						</el-form-item>
						<el-form-item
							v-if="ruleForm.cooperateWillingness != 3"
							label="融资金额:"
						>
							<div>
								{{ ruleForm.minFinancing }}
								<span>万元</span>
								<span>至</span>
								{{ ruleForm.maxFinancing }}
								<span>万元</span>
							</div>
						</el-form-item>
						<el-form-item
							v-if="ruleForm.cooperateWillingness != 3"
							label="利率区间:"
						>
							<div>
								{{ ruleForm.interestRateMin }}
								<span>%</span>
								<span>至</span>
								{{ ruleForm.interestRateMax }}
								<span>%</span>
							</div>
						</el-form-item>
						<el-form-item
							label="融资期限:"
							prop="networkSimpleName"
							v-if="ruleForm.cooperateWillingness != 3"
						>
							<span v-if="ruleForm.loanDeadline"
								>{{ ruleForm.loanDeadline }} 月</span
							>
						</el-form-item>

						<el-form-item
							v-if="ruleForm.cooperateWillingness != 3"
							label="还款方式:"
							prop="financialOrganizationsName"
						>
							{{ ruleForm.repaymentMethod }}
						</el-form-item>

						<el-form-item
							v-if="ruleForm.cooperateWillingness != 3"
							label="担保条件:"
						>
							{{ ruleForm.guaranteeConditions }}
						</el-form-item>

						<el-form-item
							v-if="ruleForm.cooperateWillingness == 3"
							label="放弃原因:"
						>
							{{ ruleForm.abandonReason }}
						</el-form-item>
						<el-form-item
							v-if="ruleForm.cooperateWillingness != 3"
							label="授信准入:"
						>
							{{ ruleForm.creditAccess }}
						</el-form-item>
						<el-form-item
							v-if="ruleForm.feedbackInformation"
							label="悦融易反馈内容:"
						>
							<div
								v-html="fn(ruleForm.feedbackInformation || '')"
							></div>
						</el-form-item>
					</el-form>
					<el-empty v-else :description="msg"></el-empty>
				</el-card>
				<div class="demo-drawer__footer">
					<el-button
						@click="$emit('closeVisibleReply')"
						v-if="paramsId.refType == 20"
						type="primary"
						>反馈</el-button
					>
					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { readCooperationFeedback } from '@/api/index.js';
export default {
	name: 'feedback',
	props: {
		feedbackVisible: {
			type: Boolean,
			required: true,
		},
		paramsId: {
			type: Object,
			required: true,
		},
	},
	data() {
		const Financing = (rule, value, callback) => {
			const Financing_max = this.$refs.Financing_max.value;
			if (value && Financing_max && value <= Financing_max) {
				callback();
			} else {
				if (!value) {
					callback(new Error('请输入最小值'));
				}
				if (!Financing_max) {
					callback(new Error('请输入最大值'));
				}
				if (value > Financing_max) {
					callback(new Error('最小值不能大于最大值'));
				}
			}
		};
		const InterestRate = (rule, value, callback) => {
			const interestRate_max = this.$refs.interestRate_max.value;
			if (value && interestRate_max && value <= interestRate_max) {
				callback();
			} else {
				if (!value) {
					callback(new Error('请输入最小值'));
				}
				if (!interestRate_max) {
					callback(new Error('请输入最大值'));
				}
				if (value > interestRate_max) {
					callback(new Error('最小值不能大于最大值'));
				}
			}
		};
		return {
			rules: {
				minFinancing: [
					{
						required: true,
						validator: Financing,
						trigger: ['blur', 'change'],
					},
				],
				interestRateMin: [
					{
						required: true,
						validator: InterestRate,
						trigger: ['blur', 'change'],
					},
				],
			},
			ruleForm: {
				dealStatus: '',
				cooperateWillingness: '',
				applicableProducts: '',
				minFinancing: '',
				maxFinancing: '',
				interestRateMin: '',
				interestRateMax: '',
				loanDeadline: '',
				repaymentMethod: '',
				guaranteeConditions: '',
				abandonReason: '',
				creditAccess: '',
				clientPresidentRelatedId: 0,
			},
			msg: '',
		};
	},
	methods: {
		fn(str = '') {
			return str.replace(/\n/g, '<br />');
		},
		open() {
			this.getReadCooperationFeedback({
				clientPresidentRelatedId:
					this.paramsId.clientPresidentRelatedId,
			});
		},
		async getReadCooperationFeedback(data) {
			const res = await readCooperationFeedback(data);
			if (res.returncode == 1) {
				this.msg = res.message;
			} else {
				res.cooperateWillingness = res.cooperateWillingness
					? res.cooperateWillingness + ''
					: '';
				this.ruleForm = res;
			}
		},
	},
	created() {},
	computed: {
		drawer_: {
			get() {
				return this.feedbackVisible;
			},
			set(v) {
				this.$emit('update:feedbackVisible', v);
			},
		},
	},
};
</script>
<style lang="scss" scoped>
.box-card {
	margin-bottom: 10px;
}
::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;
	.el-button {
		width: 15%;
	}
}
.form1 ::v-deep .el-form-item {
	margin-bottom: 0;
	padding-left: 20px;
}
.text-item {
	text-indent: 40px;
	margin: 20px 0;
}
::v-deep .el-input-number__increase {
	display: none;
}
::v-deep .el-input-number__decrease {
	display: none;
}
::v-deep .el-input-number input {
	text-align: left !important;
}
.info {
	line-height: 22px;
	margin: 10px 15px 20px 20px;
	text-indent: 0px;
	display: flex;
}
</style>
